<template>
  <div style="overflow-y: auto">
    <div class="card card-custom gutter-b" style="overflow-y: auto">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report By Locations:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div v-if="isGenerated && selected.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
          <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
              <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                <p class="font-weight-bold" style="font-size: 24px;">No devices.</p>
                <p style="font-size: 16px;">There are currently no devices.</p>
              </div>
          </div>
      </div>
      <div v-if="isGenerated && generatedResults.length > 0" class="card-body pt-0">
        <DeviceLogisticReportTable
          :traffic="traffic"
          :generatedResults="generatedResults"
          :selectedDevices="selected"
          :deviceType="this.deviceType"
        ></DeviceLogisticReportTable>
      </div>
      <div v-else-if="isGenerated && generatedResults.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
          <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
              <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                <p class="font-weight-bold" style="font-size: 24px;">No transactions.</p>
                <p style="font-size: 16px;">There are currently no transactions for this device type.</p>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeviceLogisticReportTable from '../../../content/components/device-logistic-report-table/DeviceLogisticReportTable'

export default {
  name: 'LocationsTraffic',
  props: {
    traffic: {
      type: Array,
    },
    generatedResults: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    range: {
      type: Object,
    },
    deviceType: {
      type: String,
      default: 'Saldo Reducers'
    },
  },
  components: {
    DeviceLogisticReportTable,
  },
  data() {
    return {
      isGenerated: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 2000)
  },
}
</script>

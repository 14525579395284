<template>
  <div>
    <div class="d-flex">
      <div class="card-toolbar mb-2 mr-2">
      </div>
    </div>
    <div class="table-responsive mb-0 pb-0">
      <table
        v-if="isGenerated"
        class="table table-head-custom table-vertical-center table-head-bg"
      >
        <thead>
          <tr class="text-uppercase">
            <th class="pl-6">
              <span @click="orderByDeviceName" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': deviceNameOrder.isActive }"
                >
                  {{ $t('COMPANIES.DEVICE_NAME') }}
                </span>
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': deviceNameOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="deviceNameOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th class="text-right">{{ $t('GENERAL.LOCATION') }}</th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
              {{ $t('GENERAL.LESSOR') }}
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming' && deviceType !== 'Prize Devices'"
              class="text-right"
            >
              {{ deviceType === 'Saldo Reducers' ? 'Saldo' : deviceType === 'Monsters' && 'Token' }}
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
              {{ this.deviceType === 'Saldo Reducers' ? 'Saldo minimum' : this.deviceType === 'Monsters' ? 'Token minimum' : this.deviceType === 'Prize Devices' && 'Dropped prizes'}}
            </th>
          </tr>
        </thead>
        <tbody v-if="data.length > 0">
          <tr
            v-for="(rDevice, key) in data"
            :key="key + '_' + key"
          >
            <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
                rDevice.name
              }}</span>
              <div>
                <span
                  v-for="(tag, key) in rDevice.tags"
                  :key="key"
                  class="label label-sm label-light-primary label-inline mr-2"
                  >{{ tag }}</span
                >
              </div>
              <div class="mt-1">{{ rDevice.imei }}</div>
            </td>
            <td class="text-right" :class="{ 'border-top-0': key === 0 }">
              {{ rDevice.location }}
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              {{ rDevice.lessor }}
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && deviceType !== 'Prize Devices'"
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              {{ deviceType === 'Saldo Reducers' ? rDevice.saldo : deviceType === 'Monsters' && rDevice.token  }}
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
             {{ deviceType === 'Saldo Reducers' ? rDevice.saldoMinimum : deviceType === 'Monsters' ? rDevice.tokenMinimum : rDevice.droppedPrizes }}
            </td>
          </tr>
          <tr>
            <td
              class="pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase"
            >
              {{ $t('GENERAL.TOTAL') }}:
            </td>
            <td></td>
            <td v-if="currentUserCompanyModule[0] === 'gaming'"></td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && deviceType !== 'Prize Devices'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="deviceType === 'Saldo Reducers' ? this.generateReportSaldo !== 0 : this.generateReportToken !== 0">{{
                deviceType === 'Saldo Reducers' ? this.generateReportSaldo : this.generateReportToken
              }}</span>
              <span v-else>0</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="deviceType === 'Saldo Reducers' ? this.generateReportSaldoMinimum !== 0 : deviceType === 'Monsters' ? this.generateReportTokenMinimum : this.generateReportPrizes">{{
                deviceType === 'Saldo Reducers' ? this.generateReportSaldoMinimum : deviceType === 'Monsters' ? this.generateReportTokenMinimum : this.generateReportPrizes
              }}</span>
              <span v-else>0</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeviceLogisticReportTable',
  props: {
    traffic: { type: Array },
    selectedDevices: { type: Array },
    deviceType: {
      type: String,
      default: 'Saldo Reducers'
    },
    generatedResults: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
  },
  data() {
    return {
      data: [],
      generateReportToken: 0,
      generateReportTokenMinimum: 0,
      generateReportSaldo: 0,
      generateReportSaldoMinimum: 0,
      generateReportPrizes: 0,
      saldo: 0,
      saldoMinimum: 0,
      saldoTotal: 0,
      token: 0,
      amounts: 0,
      droppedPrizes: 0,
      tokenMinimum: 0,
      tokenTotal: 0,
      prizes: 0,
      prizesMinimum: 0,
      prizesTotal: 0,
      deviceNameOrder: { query: 'asc', isActive: false },
      tokenOrder: { query: 'asc', isActive: false },
      totalOrder: { query: 'asc', isActive: false },
      isGenerated: false,
      isAdvancedView: false,
    }
  },
  mounted() {    
    this.traffic.forEach((device) => {
      let parsedPrizeMeta = JSON.parse(device.prizeMeta);
      let deviceAmounts = 0;

      let deviceTranslations = this.generatedResults.filter((item) => item.deviceId === device.id).length > 0 ? this.generatedResults.filter((item) => item.deviceId === device.id) : []
      let deviceLessor =  this.generatedResults.filter((item) => item.deviceId === device.id).length > 0 ? this.generatedResults.find((item) => item.deviceId === device.id).lessor : ''
      for(const transaction of deviceTranslations) {
        deviceAmounts += parseFloat(transaction.amount);
      }

      if(this.deviceType === 'Saldo Reducers'){ 
        this.generateReportSaldo += parseFloat(device.saldo);
        this.generateReportSaldoMinimum += parseFloat(device.saldoMinimum);
      }
      else if(this.deviceType === 'Monsters'){ 
        this.generateReportToken += parseFloat(device.token ?? 0);
        this.generateReportTokenMinimum += parseFloat(device.tokenMinimum);
      }
      else if(this.deviceType === 'Prize Devices'){ 
        this.generateReportPrizes += parsedPrizeMeta.frequencyInEuros && parseFloat(deviceAmounts) > 0 ? parseFloat(Math.floor(parseFloat(deviceAmounts)/ parsedPrizeMeta.frequencyInEuros)) : 0
      }

      
    
      this.data.push({
          name: device.name,
          imei: device.imei,
          location: device.location.title,
          address: device.address,
          tags: device.tags,
          paymentType: device.paymentType,
          deviceSaldo: device.deviceSaldo,
          lessor: deviceLessor,
          deviceId: device.deviceId,
          token: parseFloat(device.token ? device.token : 0),
          droppedPrizes: parsedPrizeMeta && parsedPrizeMeta.frequencyInEuros && deviceAmounts > 0 ? parseFloat(Math.floor(deviceAmounts / parsedPrizeMeta.frequencyInEuros)) : 0,
          prizeMeta: device.prizeMeta ?? null,
          saldo: parseFloat(device.saldo),
          saldoMinimum: parseFloat(device.saldoMinimum),
          tokenMinimum: parseFloat(device.tokenMinimum),
          isSaldoReducer: device.isSaldoReducer,
          isPrizeDevice: device.isPrizeDevice,
          isMonsterDevice: device.isMonsterDevice,
        })
    })

    setTimeout(() => {
      this.isGenerated = true
    }, 1000)
  },
  methods: {
    orderByDeviceName() {
      this.clearActiveFilters()
      this.deviceNameOrder.isActive = true

      if (this.deviceNameOrder.query === 'asc') {
        this.deviceNameOrder.query = 'desc'
        this.data.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      } else {
        this.deviceNameOrder.query = 'asc'
        this.data.sort((a, b) => {
          if (a.name > b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      }
    },
    orderByToken() {
      this.clearActiveFilters()
      this.tokenOrder.isActive = true

      if (this.tokenOrder.query === 'asc') {
        this.tokenOrder.query = 'desc'
        this.data.sort((a, b) => a.token - b.token)
      } else {
        this.tokenOrder.query = 'asc'
        this.data.sort((a, b) => b.token - a.token)
      }
    },
    orderByTotal() {
      this.clearActiveFilters()
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') {
        this.totalOrder.query = 'desc'
        this.data.sort((a, b) => a.total - b.total)
      } else {
        this.totalOrder.query = 'asc'
        this.data.sort((a, b) => b.total - a.total)
      }
    },
    clearActiveFilters() {
      this.deviceNameOrder.isActive = false
      this.totalOrder.isActive = false
    },
  },
}
</script>
